import imgBlancpain from '@assets/images/clients/blancpain.svg';
import imgDebiopharm from '@assets/images/clients/debiopharm.svg';
import imgEhl from '@assets/images/clients/ehl.svg';
import imgEper from '@assets/images/clients/eper.svg';
import imgHesav from '@assets/images/clients/hesav.svg';
import imgIllustre from '@assets/images/clients/illustre.svg';
import imgABLV from '@assets/images/clients/ablv.svg';
import imgMjf from '@assets/images/clients/mjf.svg';
import imgMobiliere from '@assets/images/clients/mobiliere.svg';
import imgNespresso from '@assets/images/clients/nespresso.svg';

export const agency = [
	{ title: 'La Mobilière', image: imgMobiliere },
	{ title: 'Montreux Jazz Festival', image: imgMjf },
	{ title: 'Blancpain', image: imgBlancpain },
	{ title: 'EHL - École Hôtelière de Lausanne', image: imgEhl },
	{ title: 'ABLV', image: imgABLV },
	{ title: 'Debiopharm', image: imgDebiopharm },
	{ title: 'EPER', image: imgEper },
	{ title: 'HESAV', image: imgHesav },
	{ title: 'L’Illustré', image: imgIllustre },
	{ title: 'Nespresso', image: imgNespresso },
];

export const branding = [
	{ title: 'Montreux Jazz Festival', image: imgMjf },
	{ title: 'La Mobilière', image: imgMobiliere },
	{ title: 'Blancpain', image: imgBlancpain },
	{ title: 'EHL - École Hôtelière de Lausanne', image: imgEhl },
	{ title: 'ABLV', image: imgABLV },
];

export const home = agency; // on homepage
export const websolutions = agency; // on web-solutions page
