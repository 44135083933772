import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ItemLogo from '@components/molecules/items/ItemLogo';

const ListClients = ({ utility, className, clientsList, ...otherProps }) => {
	const rootClass = cx('list-clients', className);

	return (
		<div
			className={rootClass}
			role="list"
			aria-label="Clients List"
			{...otherProps}
		>
			{clientsList.map((client, index) => (
				<ItemLogo
					key={`client-${index}`}
					image={{
						src: client.image,
						alt: client.title,
					}}
					link={client.link}
					role="listitem"
				/>
			))}
		</div>
	);
};

ListClients.propTypes = {
	className: PropTypes.string,
	clientsList: PropTypes.array.isRequired,
};

export default ListClients;
